const VERANSTALTUNGSTYP_FAVORIT = 'favorit';
const VERANSTALTUNGSTYP_BESTIMMTE = 'bestimmte';

const COOKIE_NAME_FAVORIT = 'abholfiliale';

const SELECTOR_CONTAINER = '.veranstaltung-container';
const SELECTOR_ITEM = '.veranstaltung-item';
const SELECTOR_LINK = '.link-zum-veranstaltungskalender';

class ContentVeranstaltung extends HTMLElement {

    connectedCallback() {
        this.setElements();
        this.initVeranstaltung();
    }

    setElements() {
        this.container = this.querySelector(SELECTOR_CONTAINER);
        this.veranstaltungsItem = this.querySelector(SELECTOR_ITEM);
        this.veranstaltungsLink = this.querySelector(SELECTOR_LINK);

        this.veranstaltungstyp = this.container.dataset.veranstaltungstyp;
        this.veranstaltungsId = this.container.dataset.veranstaltungsId;
        this.loadVeranstaltungByIdUrl = this.container.dataset.loadVeranstaltungByIdUrl;
        this.kalenderUrlTemplate = this.container.dataset.kalenderUrl;
        this.loadUrlTemplate = this.container.dataset.loadUrl;
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
        return null;
    }

    initVeranstaltung() {
        if (this.veranstaltungstyp === VERANSTALTUNGSTYP_FAVORIT) {
            let filialSapId = this.getCookie(COOKIE_NAME_FAVORIT);
            if (filialSapId === null) {
                return;
            }
            this.loadVeranstaltungBySapId(filialSapId);
        }
        else if(this.veranstaltungstyp === VERANSTALTUNGSTYP_BESTIMMTE) {
            if (!this.veranstaltungsId) {
                return;
            }
            this.loadVeranstaltungById(this.veranstaltungsId);
        }
    }

    loadVeranstaltungBySapId(filialSapId) {
        const kalenderUrl = this.replaceInUrl(this.kalenderUrlTemplate, '{sapId}', filialSapId);
        const loadUrl = this.replaceInUrl(this.loadUrlTemplate, '{sapId}', filialSapId);
        fetch(loadUrl)
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.text();
            })
            .then(text => this.onVeranstaltungReceived(text, kalenderUrl))
            .catch(error => console.error(error));
        
    }

    loadVeranstaltungById(veranstaltungsId) {
        const kalenderUrl = this.kalenderUrlTemplate;
        const loadUrl = this.replaceInUrl(this.loadVeranstaltungByIdUrl, '{veranstaltungsId}', veranstaltungsId);
        fetch(loadUrl)
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.text();
            })
            .then(text => this.onVeranstaltungReceived(text, kalenderUrl))
            .catch(error => console.error(error));
        
    }

    replaceInUrl(url, stringToReplace, replacement) {
        return url.replace(stringToReplace, replacement);
    }

    onVeranstaltungReceived(text, kalenderUrl) {
        if (!text) {
            return;
        }

        this.veranstaltungsItem.innerHTML = text;
        if (this.veranstaltungsLink !== null) {
            this.veranstaltungsLink.href = kalenderUrl;
        }
        this.changeVisibility(this.container, true);
    }

    changeVisibility(element, visible) {
        if (visible) {
            element.removeAttribute('hidden');
        } else {
            element.setAttribute('hidden', true);
        }
    }
}



if (!customElements.get('content-veranstaltung')) {
    customElements.define('content-veranstaltung', ContentVeranstaltung);
}
